<template>
    <div class="vip-content ksdg">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            快速订购
        </div>
        <div class="vip-content-inner">
            <div class="quick-ordering">
                <p> 输入产品编号和数量，或者上传产品清单，以更加方便和快速地购买您常用的产品。</p>

                <div class="tab">
                    <div class="item" :class="{active:cur === 0}" @click="cur = 0">
                        <h3>手工输入</h3>
                        <p>一次输入一个产品</p>
                    </div>
                    <div class="item" :class="{active:cur === 1}" @click="cur = 1">
                        <h3>大批上传</h3>
                        <p>上传产品清单</p>
                    </div>
                </div>

                <div class="mod1" v-if="cur === 0">
                    <div class="row" v-for="(item,index) in goodNumberList" :key="index">
                        <div class="left">
                            <el-input v-model="item.goodNumber" placeholder="请输入产品编号"></el-input>
                        </div>
                        <el-input-number v-model="item.num" :min="1"></el-input-number>
                    </div>
                    <div class="row single">
                        <div class="left">
                            <span>添加行</span>
                            <el-select v-model="selectaddline" @change="addlineChange">
								<el-option label="5" value="5"></el-option>
								<el-option label="10" value="10"></el-option>
								<el-option label="20" value="20"></el-option>
								<el-option label="45" value="45"></el-option>
							</el-select>
                        </div>
                    </div>
                    <div class="row">
                        <a href="javascript:;" @click="rebuild()">重设</a>
<!--                        <div class="g-btn" @click="joinCart()">-->
<!--                            <img src="@/assets/img/cart2.png" alt="">-->
<!--                            添加到购物车-->
<!--                        </div>-->
                        <el-button @click="joinCart()" type="primary" class="g-btn displayAli_cen" style="background: #eb6100;border: #eb6100" :loading="loading">
                            <div v-if="loading">添加中</div>
                            <div v-else class="displayAli_cen">
                                <img src="@/assets/img/cart2.png" alt="">
                                <div>添加到购物车</div>
                            </div>
                        </el-button>

                    </div>
                </div>
                <div class="mod2" v-if="cur === 1">
                    <div class="row">
<!--						<p>输入或粘贴产品编号、规格、数量，以逗号隔开每个产品独立一行</p>-->
						<p>输入或粘贴产品编号、数量，以英文逗号隔开每个产品独立一行</p>
                        <div class="btn-wrap">
                            <div class="g-btn" @click="downTemplate">
                                <img src="@/assets/img/i-download-w.png" alt="">
                                下载模板
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <textarea placeholder="例如:
												A5000，10
												A5000，10
												A5000，10
												A5000，10" v-model="inventory"></textarea>
                        <div class="upload-wrap">
<!--							上传到后台解析-->
							<el-upload
								class="upload-demo"
								drag
								action='/api/blade-goods/goods-info/import-cart-goods-info'
								:limit="100000"
                                :multiple="false"
								:headers="uploadHeaders"
								:on-error="uploadError"
								:on-success="uploadSuccess"
                                :on-progress="onProgress"
								:show-file-list="false"
								:file-list="fileList">
								<img src="@/assets/img/i-upload.png" alt="">
								<div class="el-upload__text">拖放或点击excel文件
									进行上传
								</div>
							</el-upload>
<!--							前端自主解析-->
<!--							<el-upload-->
<!--								class="upload-demo"-->
<!--								action-->
<!--								drag-->
<!--								accept = ".xlsx, .xls"-->
<!--								:auto-upload="false"-->
<!--								:show-file-list="false"-->
<!--								:on-change="handle">-->
<!--								<img src="@/assets/img/i-upload.png" alt="">-->
<!--								<div class="el-upload__text">拖放或点击excel文件-->
<!--									进行上传-->
<!--								</div>-->
<!--							</el-upload>-->
                        </div>
                    </div>
                    <el-button @click="joinCart1()" type="primary" class="g-btn displayAli_cen" style="background: #eb6100;border: #eb6100" :loading="loading">
                        <div v-if="loading">添加中</div>
                        <div v-else class="displayAli_cen">
                            <img src="@/assets/img/cart2.png" alt="">
                            <div>添加到购物车</div>
                        </div>
                    </el-button>
<!--                    <div class="g-btn add" @click="joinCart()">-->
<!--                        <img src="@/assets/img/i-add.png" alt="">-->
<!--                        加入购物车-->
<!--                    </div>-->

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {numGoodsInfoList,exportCartTemplate} from "@/api/Product";
import Bus from "@/utils/bus";
import * as XLSX from 'xlsx/xlsx.mjs'
import {joincartSave} from "@/api/transaction";
import {getToken} from "@/utils/auth";
import { Loading } from 'element-ui';
export default {
    name: "quickOrdering",
    data() {
        return {
            cur: 1,
			goodNumberList:[ // 产品编号行数
				{
					goodNumber:"",
					num:1
				},
				{
					goodNumber:"",
					num:1
				},
			],
			selectaddline:"", // 选择添加行

			uploadHeaders:{
				"blade-auth":localStorage.getItem('Token'),
				'Authorization':"Basic c2FiZXI6c2FiZXJfc2VjcmV0",
				'Tenant-Id':'000000'
			},
			fileList:[],
			inventory:"",

            loading:false, //加载中

        }
    },
	mounted(){
	},
	methods:{
    	// 添加行
		addlineChange(e){
			console.log(e)
			for (var i = 1; i <= e; i++) {
				let goodNmber={
					goodNumber:"",
					num:1
				}
				this.goodNumberList.push(goodNmber)
			}
			this.selectaddline=''  // 清空选中的行数
		},
		// 重设
		rebuild(e){
			this.goodNumberList=[ // 产品编号行数
				{
					goodNumber:"",
					num:1
				},
				{
					goodNumber:"",
					num:1
				},
			]
		},

        // 下载模板
        downTemplate(){
            if(!localStorage.getItem('Token') || this.$utils.isTokenExpired()){
                Bus.$emit('re-login', '未登录')
                return
            }
            window.location.href = 'https://chn.yifanglab.com/api/blade-goods/goods-info/export-cart-template?blade-auth='+getToken();

        },

        // 文件上传时的钩子
        onProgress(event, file, fileList){
            console.log('正在上传',event,file,fileList)
            this.loadingInstance = Loading.service({
                lock: true,
                text: '正在上传...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
		uploadSuccess(response, file, fileList){ // 上传成功
			console.log('上传成功',response,file,fileList)
			this.$message({
				type: 'success',
				message: "上传成功",
				duration: 2000
			});
            let list=[]
            if(response.data){
                response.data.forEach(i=>{
                    let inventory=i.goodsNumber+ ','+ i.quantity
                    list.push(inventory)
                })
                this.inventory=list.join('\n')
            }
            setTimeout(()=>{
                this.loadingInstance.close();
            },1000)
			// this.jobList[this.noteIndex].file=response.data
		},
		uploadError(err, file, fileList){ // 上传失败
            // console.log('上传失败',response,file,fileList)
            console.log('上传失败',)
            if(JSON.parse(JSON.stringify(err)).status==401){
                if(!localStorage.getItem('Token') || this.$utils.isTokenExpired()){
                    Bus.$emit('re-login', '未登录')
                }
            }else{
                this.$message({
                    type: 'error',
                    message: "上传失败",
                    duration: 2000
                });
            }

            this.loadingInstance.close();

        },
		readFile(file){//文件读取
			return new Promise(resolve => {
				let reader = new FileReader();
				reader.readAsBinaryString(file);//以二进制的方式读取
				reader.onload = ev =>{
					resolve(ev.target.result);
				}
			})
		},
		// 监听文件上传状态
		async handle(ev){
			let file = ev.raw;
			console.log(file)
			if(!file){
				console.log("文件打开失败")
				return ;
			}else{
                let loadingInstance = Loading.service({
                    lock: true,
                    text: '正在解析...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
				let data = await this.readFile(file);
				let workbook = XLSX.read(data,{ type: "binary"});//解析二进制格式数据
				console.log('二进制数据的解析:')
				console.log(workbook)
				let worksheet = workbook.Sheets[workbook.SheetNames[0]];//获取第一个Sheet
				let result = XLSX.utils.sheet_to_json(worksheet);//json数据格式
				console.log('最终解析的 json 格式数据:')
				console.log(result)
				let list=[]
				result.forEach(i=>{
					let inventory=i['商品编号']+ ','+ i['商品数量']
					list.push(inventory)
				})
				this.inventory=list.join('\n')
                this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                    setTimeout(()=>{
                        loadingInstance.close();
                    },1000)
                });
				console.log(list)

			}
		},

		// ——————————————————————————————接口请求
		// 手工输入 加入购物车
		joinCart(){
			let that=this
			if(!localStorage.getItem('Token') || this.$utils.isTokenExpired()){
				Bus.$emit('re-login', '未登录')
				return
			}
			const list=JSON.parse(JSON.stringify(that.goodNumberList)) // 深拷贝行列表
			list.forEach((i,index)=>{
				if(i.goodNumber==''){
					list.splice(index,1)
				}
			})
            this.getGoodsInfo(list)
            // console.log()
            // numGoodsInfoList
            return
			console.log('加入购物车校验')

		},

    //    批量根据商品编号获取商品id
        getGoodsInfo(list){
		    let goodsNumbers=[]
            this.loading=true

            list.forEach(i=>{
                goodsNumbers.push(i.goodNumber)
            })
            let param={
                goodsNumbers:goodsNumbers.join(','),
            }
            let goodslist=[]

            numGoodsInfoList(param).then((res) => {
                let data=res.data.data
                if(res.data.code==200){
	                this.loading=false
					if(data.length==0){
                        this.$message({
                            type: 'error',
                            message: '产品编号信息有误',
                            duration: 1500
                        });
                    }else{
						data.forEach((i,index)=>{
							list.forEach((j,index1)=>{
								// console.log(i.goodsNumber,j.goodNumber)
								if(i.goodsNumber==j.goodNumber){
									let info={
										id:i.id,
										num:j.num,
									}
									console.log(data.length,index+1)
									console.log(index1)
									let islast=data.length==index+1 ?true:false // 是否添加最后一遍
									this.joincart(info,islast)
								}
							})
						})
						// console.log(goodslist)
					}
                }else{
                    this.loading=false
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1500
                    });
                }
            })

        },
        // 挨个加入购物车
        joincart(item,islast){
            let param={
                goodsId:item.id,
                customerId:localStorage.getItem('customId'),
                count:item.num
            }
            joincartSave(param).then((res) => {
                if(res.data.code==200){
                    if(islast){ // 最后一个
                        this.loading=false
                        this.$message({
                            type: 'success',
                            message: '添加成功',
                            duration: 1500
                        });
                        this.goodNumberList=[]
                        console.log(this.goodNumberList)
                        Bus.$emit('addCart', '加购物车')
                    }

                }else{
                    this.loading=false
                    this.$message({
                        type: 'error',
                        message: '添加中断，请检查输入格式！',
                        // message: res.data.message,
                        duration: 1500
                    });
                }
            })
        },

    //    批量上传 加入购物车
        joinCart1(){
            if(!localStorage.getItem('Token') || this.$utils.isTokenExpired()){
                Bus.$emit('re-login', '未登录')
                return
            }
            if(this.inventory){
                let list=[]
                this.inventory.split('\n').forEach(i=>{
                    list.push(i.split(','))
                })
                let list1=[]
                list.forEach(i=>{
                    // console.log(i[0],i[1])
                    let info={
                        goodNumber:i[0],
                        num:i[1]
                    }
                    list1.push(info)
                })
                // console.log(list1)

                this.getGoodsInfo(list1)

            }

        },

    },
}
</script>

<style>
.vip-content.ksdg .el-button.g-btn span{
    color: #fff !important;
}
</style>
<style lang="sass">
//.el-button.g-btn{
//    span{
//        color: #fff !important;
//    }
//}
//.vip-content.ksdg{
//
//}

</style>
