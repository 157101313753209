import { render, staticRenderFns } from "./2quickOrdering.vue?vue&type=template&id=bc6d62c6"
import script from "./2quickOrdering.vue?vue&type=script&lang=js"
export * from "./2quickOrdering.vue?vue&type=script&lang=js"
import style0 from "./2quickOrdering.vue?vue&type=style&index=0&id=bc6d62c6&prod&lang=css"
import style1 from "./2quickOrdering.vue?vue&type=style&index=1&id=bc6d62c6&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports